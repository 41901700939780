import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@ff/auth';
import { LoaderService } from '@ff/loader';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordLinkComponent } from '../reset-password-link/resetPasswordLink.component';
import { ResetPasswordComponent } from '../reset-password/resetPassword.component';
import { AffiliateService } from './../../../../services/admin/affiliate.service';
import { SharedService } from './../../../../services/shared/shared.service';

@Component({
  selector: 'app-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public error: any;

  /**
   * Reset password link modal
   */
  public showResetPasswordLinkModal: boolean;

  /**
   * Reset password modal
   */
  public showResetPasswordModal: boolean;

  public token: string;

  constructor(
    private _authService: AuthService,
    fb: FormBuilder,
    private _router: Router,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _matDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _loaderService: LoaderService,
    private _affiliateService: AffiliateService,
    private _sharedService: SharedService,
    private _translate: TranslateService
  ) {
    this.error = {};

    this.loginForm = fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.showResetPasswordLinkModal = false;

    this._activatedRoute.queryParams.subscribe(
      (params: any) => {
        if (params.token) {
          this.token = params.token;
          this.showResetPasswordDialog();
        }
      }
    );

    const user = this._userService._user.getValue();
    if (user) {
      this.handleLoggedInUser(user);
    }
  }

  public redirect(url: string) {
    window.location.href = url;
  }

  public login(form: FormGroup) {

    // Prevent spam submit
    if (form.valid && !this._loaderService.isVisible()) {

      this._loaderService.show();

      this._authService.login(form.controls.login.value, form.controls.password.value).then(
        (user: any) => {
          this._loaderService.hide();
          this.handleLoggedInUser(user);
        }
      ).catch(
        (response) => {
          this._loaderService.hide();
          this._snackBar.open(response.error.message, 'Close', {
            duration: 3000
          });
        }
      );
    }
  }

  public showResetPasswordLinkDialog() {
    this._matDialog.open(ResetPasswordLinkComponent);
  }

  public showResetPasswordDialog() {

    this._matDialog.open(ResetPasswordComponent, {
      data: { token: this.token }
    });
  }

  ngOnInit() {
  }

  private handleLoggedInUser(user) {
    this.setCurrentAffiliate(user);
    this.redirectUser(user);
  }

  private setCurrentAffiliate(user) {
    const currentAffiliate = user.current_affiliate ? user.current_affiliate : user.affiliates[0];
    this._sharedService.setCurrentAffiliate(currentAffiliate);
  }

  private redirectUser(user) {
    user.is_admin ? this._router.navigate(['/admin']) :
      this._router.navigate(['/shipments']);
  }
}
