import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { endpoints } from './../../../environments/endpoints';
import { environment } from './../../../environments/environment';
import { Trip } from './../../models/trip.model';
import { CrudService } from './../crud.service';

@Injectable({
  providedIn: 'root'
})
export class TripsService extends CrudService {
  private httpClient: HttpClient;

  viewSwitch = 'week';
  selectedDay: moment.Moment;
  weekStart: moment.Moment;
  weekEnd: moment.Moment;

  trips: MatTableDataSource<Trip> = new MatTableDataSource<Trip>();
  weekTrips: any[] = [];
  dayTrips: MatTableDataSource<Trip> = new MatTableDataSource<Trip>();

  constructor(http: HttpClient) {
    super(http, endpoints.trips);
    this.httpClient = http;
  }

  getStatuses(): Observable<any> {
    return this.httpClient.get(environment.api.host + endpoints.tripStatuses);
  }

  updateStatus(trip: Trip): Observable<any> {
    return this.httpClient.post(environment.api.host
      + endpoints.trips + '/' + trip.id + endpoints.updateStatus,
      { status_id: trip.trip_status_id });
  }

  getAffiliateTrips(affiliateId: number): Observable<any> {
    return this.httpClient.post(environment.api.host
      + endpoints.affiliates + '/' + affiliateId
      + endpoints.trips, { week_start_date: this.weekStart.toISOString() });
  }

  import(loadedFile: { file: File; reader: FileReader; }): Observable<any> {
    return this.httpClient.post(environment.api.host + endpoints.trips + endpoints.import,
      {
        file: { name: loadedFile.file.name, type: loadedFile.file.type },
        csv_data: loadedFile.reader.result,
        week_start_date: this.weekStart.toISOString()
      });
  }

  export(tripId: number) {
    return this.httpClient.get(environment.api.host
      + endpoints.trips + '/' + tripId
      + endpoints.export,
      { responseType: 'blob', observe: 'response' }
    );
  }
}
