import { Router, Routes } from '@angular/router';
import { AuthService } from '@ff/auth';
import { LoggedInGuard } from '@ff/core';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { routes as authRoutes } from '../auth/auth.routes';
import { routes as coreRoutes } from '../core/core.routes';
import { routes as userRoutes } from '../user/user.routes';
import { AdminGuard } from './guards/admin.guard';

// Return type can be Route[] | Promise<Route[]> | Observable<Route[]>
export const navigationFactory = (
    authService: AuthService,
    userService: UserService,
    router: Router,
    translateService: TranslateService
) => {
    let navItems: Routes = [];

    const isUserLoggedIn = authService.isLoggedIn().getValue();

    // Only display navigation for logged in users
    if (isUserLoggedIn) {
        const authUser = userService._user.getValue();
        getNavItems(navItems, authUser);
    }

    translateService.onLangChange.subscribe(
        (event) => {
            navItems.forEach(
                (item) => {
                    translateService.get(item.data.key).subscribe(
                        (translation) => {
                            item.data.title = translation;
                        }
                    );
                }
            );
        }
    );

    userService._user.subscribe(user => {
        if (user) {
            navItems = [];
            navItems = getNavItems(navItems, user);
            router.resetConfig(navItems.concat(authRoutes).concat(userRoutes));
        }
    });

    router.resetConfig(navItems.concat(authRoutes).concat(userRoutes).concat(coreRoutes));

    return navItems;
}

function getNavItems(navItems: Routes, authUser: any) {
    navItems.push(
        {
            path: 'shipments', data: { title: 'Shipments', key: 'general.page_titles.shipments' },
            canActivate: [LoggedInGuard],
            loadChildren: () => import('../shipments/shipments.module').then(m => m.ShipmentsModule)
        }
    );

    navItems.push(
        {
            path: 'trips-planning', data: { title: 'Trips Planning', key: 'general.page_titles.trips_planning' },
            canActivate: [LoggedInGuard],
            loadChildren: () => import('../trips-planning/trips-planning.module').then(m => m.TripsPlanningModule)
        }
    );

    if (authUser.is_admin) {
        navItems.push(
            {
                path: 'admin',
                data: { title: 'Administration', key: 'general.page_titles.admin' },
                canActivate: [AdminGuard],
                loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule)
            },
        );
    }

    return navItems;
}
