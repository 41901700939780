import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Affiliate } from './../../../../models/affiliate.model';
import { AffiliateService } from './../../../../services/admin/affiliate.service';
import { SharedService } from './../../../../services/shared/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private isAdmin = false;
  currentAffiliate: Affiliate;
  affiliates: Affiliate[];

  @Input() public nav: MatSidenav;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public sharedService: SharedService,
    public affiliateService: AffiliateService,
    public translateService: TranslateService
  ) {
    this.initLanguage();
  }

  ngOnInit() {
  }

  isAdminUser() {
    return this.isAdmin;
  }

  getCurrentLang(): string {
    return this.sharedService.getAppLang();
  }

  initLanguage() {
    let lang = 'en';
    const user = this.userService._user;
    if (user && user.getValue() && user.getValue().lang_code) {
      lang = user.getValue().lang_code;
    }
    this.updateLang(lang);
  }

  updateLang(lang: string): Observable<any> {
    this.translateService.use(lang);
    return this.sharedService.setAppLang(lang);
  }

  changeCurrentAffiliate(affiliate) {
    this.currentAffiliate = affiliate;
    const userId = this.userService._user.getValue().id;
    this.sharedService.updateCurrentAffiliate(userId, affiliate.id);
  }

  getCurrentAffiliateName(): string {
    const user = this.userService._user.getValue();
    if (!user) {
      return;
    }

    let currentAffiliate = user.current_affiliate;
    if (!currentAffiliate) {
      currentAffiliate = user.affiliates[0];
    }

    return currentAffiliate.name;
  }
}
