import { Component } from '@angular/core';
import { ATInternetService } from '@ff/at-internet';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public constructor(
    private translate: TranslateService,
    public atInternet: ATInternetService
  ) {
    this.translate.setDefaultLang('en');
  }
}
