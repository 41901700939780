import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'shipments',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: 'shipments' }
];
