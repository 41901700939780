<header>
  <div class="d-flex align-items-center">
    <mat-icon *ngIf="userService.getUser() | async" class="burger pointer" (click)="nav.toggle()">menu</mat-icon>
    <img src="assets/logo.svg" title="Total" alt="Total" class="logo mr-5" />
    <div class="font-weight-bold app-title">COCKPIT LOG OPS</div>
    <div class="ml-auto mr-3"></div>
    <ng-container *ngIf=" userService.getUser() | async">
      <button mat-stroked-button color="accent" class="affiliates-button" *ngIf="!isAdminUser()"
        [matMenuTriggerFor]="affiliatesMenu">{{getCurrentAffiliateName()}}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #affiliatesMenu="matMenu">
        <button mat-menu-item *ngFor="let affiliate of userService.getUser().getValue().affiliates"
          (click)="changeCurrentAffiliate(affiliate)">
          {{affiliate.name}}
        </button>
      </mat-menu>

      <div class="pointer d-flex align-items-center user" [matMenuTriggerFor]="account">
        <span class="username">{{userService.getUser().getValue().email}}</span>
        <mat-icon class="ml-3 user-icon" color="primary">account_circle</mat-icon>
        <mat-icon color="primary" class="arrow-icon">arrow_drop_down</mat-icon>
      </div>
      <mat-menu class="mt-2" #account="matMenu" [overlapTrigger]="false">
        <button mat-menu-item routerLink="/auth/logout">Logout</button>
      </mat-menu>
    </ng-container>
    <ng-container>
      <mat-radio-group class="lang-choice d-flex align-items-center" (change)="updateLang($event.value)">
        <mat-radio-button value="fr" [checked]="getCurrentLang() === 'fr'">FR</mat-radio-button>|
        <mat-radio-button value="en" [checked]="getCurrentLang() === 'en'">EN</mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </div>
  <app-navigation *ngIf="userService.getUser() | async"></app-navigation>
</header>
