import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VesselsService } from 'src/app/services/admin/vessels.service';
import { endpoints } from './../../../environments/endpoints';
import { environment } from './../../../environments/environment';
import { Affiliate } from './../../models/affiliate.model';
import { TripsService } from './../trips-planning/trips.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private appLangSource = new BehaviorSubject<string>('en');
  currentAffiliate = new BehaviorSubject<Affiliate>(null);

  contactTypes = ['onsl', 'sitm', 'ofsl', 'onst'];

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private tripsService: TripsService,
    private vesselsService: VesselsService,
    private translate: TranslateService
  ) { }

  canUserImport(): boolean {
    const user = this.userService._user.getValue();
    if (!user) {
      return false;
    }

    return user.is_onsl_for_affiliate;
  }

  setAppLang(lang: string) {
    this.appLangSource.next(lang);

    const user = this.userService._user.getValue();
    if (!user) {
      return null;
    }

    this.http.post(environment.api.host + endpoints.saveUserLang, { user_id: user.id, lang }).subscribe(res => {
      // Really nothing to do :)
    });
  }

  getAppLang(): string {
    return this.appLangSource.getValue();
  }

  /**
   * Business
   *
   */

  getVesselStatuses(): Observable<any> {
    return this.vesselsService.getStatuses();
  }

  getTripStatuses(): Observable<any> {
    return this.tripsService.getStatuses();
  }

  getCurrentAffiliate(): Observable<Affiliate> {
    return this.currentAffiliate.asObservable();
  }

  updateCurrentAffiliate(userId: number, affiliateId: number) {
    const postResponse = this.http.post(environment.api.host + endpoints.users
      + '/' + userId
      + endpoints.saveCurrentAffiliate, { affiliate_id: affiliateId });

    postResponse.subscribe((res: any) => {
      this.setCurrentAffiliate(res.data.current_affiliate);
      this.userService.getUser().next(res.data.user);
    });
  }

  setCurrentAffiliate(affiliate: Affiliate) {
    this.currentAffiliate.next(affiliate);
  }

  /**
   * Utilities
   *
   */

  getHumanReadableSize(bytes: number) {
    const sizes = ['Octets', 'Ko', 'Mo'];
    if (bytes === 0) {
      return '0';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return (Math.round(bytes / Math.pow(1024, i) * 10) / 10) + ' ' + sizes[i];
  }

  getFormValidationErrors(form: FormGroup): Array<{ keyControl, keyError, errorValue }> {
    let validationErrors = [];

    if (form.valid) {
      return validationErrors;
    }

    Object.keys(form.controls).forEach(key => {
      const control = form.controls[key];
      if (control instanceof FormGroup) {
        validationErrors = validationErrors.concat(this.getFormValidationErrors(control));
      }

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);

          validationErrors.push({
            keyControl: key,
            keyError: this.translate.instant('general.form_validation.errors.' + keyError),
            errorValue: controlErrors[keyError]
          });
        });
      }
    });

    return validationErrors;
  }
}
