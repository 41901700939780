import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

export let ENDPOINT_TOKEN = new InjectionToken<string>('endpoint');

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(
    private http: HttpClient,
    @Inject(ENDPOINT_TOKEN) protected endpoint: string
  ) { }

  get(id: number): Observable<any> {
    return this.http.get(environment.api.host + this.endpoint + '/' + id);
  }

  getAll(): Observable<any> {
    return this.http.get(environment.api.host + this.endpoint);
  }

  save(data: any): Observable<any> {
    return this.http.post(environment.api.host + this.endpoint, data);
  }

  update(data: any, id: number): Observable<any> {
    return this.http.patch(environment.api.host + this.endpoint + '/' + id, data);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(environment.api.host + this.endpoint + '/' + id);
  }

}
