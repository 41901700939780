import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AUTH_CONFIG } from '@ff/auth';
import { OAuth2Module } from '@ff/oauth2';
import { SharedModule } from '../shared/shared.module';
import { environment } from './../../../environments/environment';
import { routes } from './auth.routes';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ResetPasswordLinkComponent } from './components/reset-password-link/resetPasswordLink.component';
import { ResetPasswordComponent } from './components/reset-password/resetPassword.component';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    ResetPasswordComponent,
    ResetPasswordLinkComponent
  ],
  entryComponents: [
    ResetPasswordComponent,
    ResetPasswordLinkComponent
  ],
  providers: [
    { provide: AUTH_CONFIG, useValue: { host: environment.api.host } }
  ],
  imports: [
    SharedModule,
    OAuth2Module,
    RouterModule.forChild(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
})
export class AuthModule {
  public constructor() {
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
