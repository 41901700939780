import { HttpClient } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { API_CONFIG } from '@ff/api';
import { ATInternetModule } from '@ff/at-internet';
import { AuthModule, AuthService } from '@ff/auth';
import { UserModule, UserService, USER_CONFIG } from '@ff/user';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../../environments/environment';
import { AuthModule as AppAuthModule } from '../auth/auth.module';
import { SharedModule } from '../shared/shared.module';
import { UserModule as AppUserModule } from '../user/user.module';
import { AppComponent } from './components/app/app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NAVIGATION_CONFIG } from './components/navigation/navigation.token';
import { routes } from './core.routes';
import { navigationFactory } from './navigation.factory';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavigationComponent
  ],
  providers: [
    {
      provide: API_CONFIG,
      useValue: {
        host: environment.api.host
      }
    },
    {
      provide: USER_CONFIG,
      useValue: {
        host: environment.api.host
      }
    }, {
      provide: NAVIGATION_CONFIG,
      useFactory: navigationFactory,
      deps: [AuthService, UserService, Router, TranslateService]
    },
  ],
  imports: [

    // Angular
    BrowserModule,
    BrowserAnimationsModule,

    // FF packages

    UserModule,
    AuthModule,
    ATInternetModule,

    // Others

    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    SharedModule,

    // App packages
    AppAuthModule,
    AppUserModule
  ],
  bootstrap: [
    AppComponent
  ]
})

export class CoreModule {
  public constructor(@Optional() @SkipSelf() parent: CoreModule) {
    if (parent) {
      throw new Error('CoreModule should not be exported then imported by another module!');
    }
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
