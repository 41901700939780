import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from '../../../environments/endpoints';
import { environment } from './../../../environments/environment';
import { CrudService } from './../crud.service';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService extends CrudService {

  private httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(http, endpoints.affiliates);

    this.httpClient = http;
  }

  getUserAffiliates(userId: number): Observable<any> {
    return this.httpClient.get(environment.api.host
      + endpoints.users + '/' + userId
      + this.endpoint);
  }

  import(fileData): Observable<any> {
    return this.httpClient.post(environment.api.host
      + this.endpoint + endpoints.import, fileData);
  }
}
