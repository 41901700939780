import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '@ff/user';
import { Observable } from 'rxjs';
import { RouterAdapterService } from './../../../services/router-adapter/router-adapter.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: RouterAdapterService,
        public userService: UserService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const currentUser = this.userService.getUser();
        if (currentUser && currentUser.getValue() && currentUser.getValue().is_admin) {
            return true;
        } else {
            this.router.goToUrl('/');
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }
}
