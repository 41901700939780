import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() step: string;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  getStepLabel() {
    return this.translate.instant('general.page_titles.' + this.step.replace('-', '_'));
  }
}
