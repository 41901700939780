export const endpoints = {
    // CRUD
    affiliates: '/affiliates',
    ccuTypes: '/ccu-types',
    shipments: '/shipments',
    sites: '/sites',
    trips: '/trips',
    users: '/users',
    vessels: '/vessels',

    // OTHERS
    import: '/import',
    export: '/export',
    messagesAndUpdates: '/messages-updates',
    newMessage: '/new-message',
    saveCurrentAffiliate: '/current-affiliate',
    saveUserLang: '/save-user-lang',
    shipmentStatuses: '/shipment-statuses',
    shipmentTrip: '/trip',
    tripStatuses: '/trip-statuses',
    vesselsForUser: '/vessels/user',
    vesselStatuses: '/vessel-statuses',
    vesselTypes: '/vessel-types',
    updateStatus: '/update-status'
}
